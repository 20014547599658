@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css);
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh);
body {
    margin: 0px !important;
    font-family: 'Montserrat' !important;
}
/* MainPart */
.test{
  text-align: right;
}


.mainPart {
    margin-top: 120px;
}
.mainPart .p15 {
    color: #fff;
}
.mainPart .h50 {
    color: #080e2c;
    line-height: 1.2;
}
.mainPart .p20 {
    color: rgba(000, 000, 000, 0.5)
}

/* PartTwo */
.partTwo .primaryContainer {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-right: 40px;
}
/* PartThree */
.partThree .primaryContainer {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-right: 40px;
}
/* PartFive */
.partFive .secondaryContainer {
    color: #fff;
}
.button {
    border-radius: 50px;
    border: 2px solid #479ff8;
    background-color: #479ff8;
    color: #fff;
    text-align: center;
    font-size: 18px;
    padding:6px;
    width: 215px;
    transition: all 0.5s;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 1%;
}

  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button:hover span {
    padding-right: 25px;
  }

  .button:hover span:after {
    opacity: 1;
    right: 0;
  }

.button-2 {
    border-radius: 5px;
    border: 2px solid grey;
    background-color: #fff;
    color: grey;
    text-align: center;
    font-size: 18px;
    padding:6px;
    width: 150px;
    transition: all 0.5s;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 1%;
}

  .button-2 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button-2 span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button-2:hover span {
    padding-right: 25px;
  }

  .button-2:hover span:after {
    opacity: 1;
    right: 0;
  }

  .blogCard {
    transition: 00.5s;
  }

  .blogCard:hover {
    margin-top: -20px;
    transition: 00.5s;
  }


  @media (min-width: 1000px) {
    .container{
        max-width: 1100px;
    }
}

/* Fonts */
/* FontAwesome */
/* Bootstrap */

body {
    overflow-x: hidden;
}
/* COMPONENTS */
/* ---------- NAVBAR ---------- */
/* MainNav */
.navbar {
    background-color: #fff !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    box-shadow: 0px 0.5px 10px rgba(000, 000, 000, 0.05);
    border-bottom: 1px solid rgba(000, 000, 000, 0.03);
}
.navbar-brand {
    color: #000 !important;
    font-weight: 100;
}
.navbar a {
    color: #000;
}
.navbar > .active > a{
    background-color: #2f2f2f;
}
.navbar .right-links a {
    margin-left: -1%;
    font-size: 16px;
    display: inline-block;
}
.navbar .right-links a:hover {
    color: #479ff8;
    text-decoration: none;
}
/* SubNav */
.subNav {
    color: #fff;
    border-radius: 0px !important;
}
.subNav a {
    color: #fff;
    margin-right: 20px;
    margin-left: 20px;
}
.subNav a:hover {
    color: #fff;
    text-decoration: none;
}











*{
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

nav:after{
  content: '';
  clear: both;
  display: table;
}
nav .logo{
  float: left;
  color: white;
  font-size: 27px;
  font-weight: 600;
  line-height: 70px;
  padding-left: 60px;
}
nav ul{
  float: right;
  margin-right: 40px;
  list-style: none;
  position: relative;
}
nav ul li{
  float: left;
  display: inline-block;
  background: white;
  margin: 0 5px;
}
nav ul li a{
  color: black;
  line-height: 70px;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 10px;
}
nav ul li a:hover{
  color: #479ff8;
  border-radius: 5px;
  box-shadow:  0 0 5px #479ff8,
               0 0 10px #fff;
}
nav ul ul li a:hover{
  box-shadow: none;
}
nav ul ul{
  position: absolute;
  top: 90px;
  /* border-top: 3px solid cyan; */
  opacity: 0;
  visibility: hidden;
  transition: top .3s;
}
nav ul ul ul{
  border-top: none;
}
nav ul li:hover > ul{
  top: 70px;
  opacity: 1;
  visibility: visible;
}
nav ul ul li{
  position: relative;
  margin: 0px;
  width: 250px;
  float: none;
  display: list-item;
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
nav ul ul li a{
  line-height: 50px;
}
nav ul ul ul li{
  position: relative;
  top: -60px;
  left: 150px;
}
.show,.icon,input{
  display: none;
}
.fa-plus{
  font-size: 15px;
  margin-left: 40px;
}
@media all and (max-width: 968px) {
  nav ul{
    margin-right: 0px;
    float: left;
  }
  nav .logo{
    padding-left: 30px;
    width: 100%;
  }
  .show + a, ul{
    display: none;
  }
  nav ul li,nav ul ul li{
    display: block;
    width: 100%;
  }
  nav ul li a:hover{
    color: cyan;
    border-radius: 5px;
    box-shadow:  0 0 5px #33ffff,
                 0 0 10px #66ffff;
  }
  .show{
    display: block;
    color: black;
    font-size: 18px;
    padding: 0 20px;
    line-height: 70px;
    cursor: pointer;
  }
  .show:hover{
    color: cyan;
  }
  .icon{
    display: block;
    color: black;
    position: absolute;
    top: 0;
    right: 40px;
    line-height: 70px;
    cursor: pointer;
    font-size: 25px;
  }
  nav ul ul{
    top: 70px;
    border-top: 0px;
    float: none;
    position: static;
    display: none;
    opacity: 1;
    visibility: visible;
  }
  nav ul ul a{
    padding-left: 40px;
  }
  nav ul ul ul a{
    padding-left: 80px;
  }
  nav ul ul ul li{
    position: static;
  }
  [id^=btn]:checked + ul{
    display: block;
  }
  nav ul ul li{
    border-bottom: 0px;
  }
  span.cancel:before{
    content: '\f00d';
  }
}
.content{
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}
header{
  font-size: 35px;
  font-weight: 600;
  padding: 10px 0;
}














/* nav {
    position: relative;
}

.hamburger {
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 15%;
    transform: translate(-5%, 50%);
    z-index: 2;
}

.nav-links  {
    display: flex;
    background: #C0C0C0;
    list-style: none;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    position: fixed;
    height:100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(0px at 100% 0%);
    -webkit-clip-path: circle(0px at 100% 0%);
    transition: all 1s ease-out;
    pointer-events: none;
}
.nav-links.open {
    clip-path: circle(1000px at 100% 0%);
    -webkit-clip-path: circle(1000px at 100% 0%);
    pointer-events: all;
}

.line{
    width: 30px;
    height: 3px;
    background: #000;
    margin: 5px;
}

.nav-links li a {
    color: #fff;
    font-size: 24px;
    text-align: center;
} */

/* ---------- BUTTONS ---------- */
/* primaryButton */
.primaryButton {
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}
/* secondaryButton */
.secondaryButton {
    padding: 15px 30px;
    background-color: #000;
    color: #fff !important;
    border-radius: 5px;
}
/* whiteButton */
.whiteButton {
    padding: 15px 30px;
    background-color: #fff;
    color: #000 !important;
    border-radius: 5px;
}
/* greenButton */
.greenButton {
    padding: 15px 30px;
    background-image: linear-gradient(#00ff2a, #13d302);
    color: #080e2c !important;
    border-radius: 5px;
    font-weight: 500;
}
/* startButton */
.startButton {
    padding: 12px 30px;
    background-image: linear-gradient(#fa9d63, #fd5e5e);
    color: #fff !important;
    border-radius: 5px;
}

/* INPUT FIELDS */
.primaryInput {
    border-radius: 5px;
    border: 1px solid black;
    /* box-shadow: 0px 3px 30px rgba(000, 000, 000, 0.1); */
    padding: 15px;
    width: 90%;
}

/* ---------- TEXTS ---------- */
.p15 {
    font-weight: 500 !important;
    font-size: 15px;
}
.p16 {
    font-weight: 600 !important;
    font-size: 16px;
}
.p18 {
    font-weight: 400 !important;
    font-size: 18px;
}
.p20 {
    font-weight: 400 !important;
    font-size: 20px;
}
.p22 {
  font-weight: 400 !important;
  font-size: 22px;
}
.p30 {
    font-weight: 400 !important;
    font-size: 30px;
}
.h22 {
    font-weight: 600 !important;
    font-size: 22px;
}
.h30 {
    font-weight: 600 !important;
    font-size: 30px;
}
.h35 {
    font-weight:600 !important;
    font-size: 35px;
}
.h40 {
    font-weight:600 !important;
    font-size: 40px;
}
.h50 {
    font-weight: 700 !important;
    font-size: 50px;
    color: #000;
}
.h60 {
    font-weight: 700 !important;
    font-size: 60px;
    color: #000;
}
/* ---------- CONTAINERS ---------- */
/* primaryContainer */
.primaryContainer {
    background-color: #fff;
    /* padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    padding-right: 5px; */
    border-radius: 5px;
    box-shadow: 0px 3px 30px rgba(000, 000, 000, 0.1);   
}
/*teamContainer*/
.teamContainer {
    background-color: #fff;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    border-radius: 100%;
    box-shadow: 0px 3px 30px rgba(000, 000, 000, 0.1);   
}
/* secondaryContainer */
.secondaryContainer {
    background-color: #fff;
    padding-top: 2px;
    padding-left: 2px;
    padding-bottom: 2px;
    padding-right: 2px;
    border-radius: 5px;
}
/* blueContainer */
.blueContainer {
    /* background-image: url("bgblue.png"); */
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    border-radius: 5px;
    color: #fff !important;
}

/* ---------- PILLS ---------- */
/* primaryPills */
.primaryPill {
    background-color: rgb(144, 201, 255);
    padding: 5px 15px;
    border-radius: 5px;
    border: 2px solid rgb(0, 43, 235);
}
/* secondaryPills */
.secondaryPill {
    background-color: rgb(144, 255, 177);
    padding: 5px 15px;
    border-radius: 5px;
    border: 2px solid rgb(0, 180, 54);
}
/* alertPills */
.alertPill {
    background-color: rgb(255, 144, 172);
    padding: 5px 15px;
    border-radius: 5px;
    border: 2px solid rgb(180, 0, 15);
}

/*——————————————
Global
———————————————*/

#content {
  margin-top: 0px;
  text-align: center;
}

section.timeline-outer {
  width: 70%;
  margin: 0 auto;
}

h1.header {
  font-size: 50px;
  line-height: 70px;
}
/* Timeline */

.timeline {
  border-left: 8px solid #42A5F5;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #333;
  margin: 20px auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  padding: 20px;
  list-style: none;
  text-align: left;
}

.timeline h1,
.timeline h2,
.timeline h3 {
  font-size: 1.1em;
}
.timeline p {
  font-size: 0.9em
}

.timeline .event {
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -177.5px;
  color: #212121;
  content: attr(data-date);
  text-align: right;
  /*  font-weight: 100;*/
  
  font-size: 16px;
  min-width: 120px;
}

.timeline .event:after {
  box-shadow: 0 0 0 8px #42A5F5;
  left: -30px;
  background: #212121;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}
/**/
/*——————————————
Responsive Stuff
———————————————*/

@media (max-width: 200px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 13px;
  }
  .timeline h3 {
    font-size: 16px;
  }
  .timeline p {
    padding-top: 20px;
  }
  section.lab h3.card-title {
    padding: 5px;
    font-size: 16px
  }
}

@media (max-width: 200px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 13px;
  }
  .timeline .event:nth-child(1)::before,
  .timeline .event:nth-child(3)::before,
  .timeline .event:nth-child(5)::before {
    top: 38px;
  }
  .timeline h3 {
    font-size: 16px;
  }
  .timeline p {
    padding-top: 20px;
  }
}
/*——————————————
others
———————————————*/

a.portfolio-link {
  margin: 0 auto;
  display: block;
  text-align: center;
}
