body {
    margin: 0px !important;
    font-family: 'Montserrat' !important;
}
/* MainPart */
.test{
  text-align: right;
}


.mainPart {
    margin-top: 120px;
}
.mainPart .p15 {
    color: #fff;
}
.mainPart .h50 {
    color: #080e2c;
    line-height: 1.2;
}
.mainPart .p20 {
    color: rgba(000, 000, 000, 0.5)
}

/* PartTwo */
.partTwo .primaryContainer {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-right: 40px;
}
/* PartThree */
.partThree .primaryContainer {
    padding-top: 40px;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-right: 40px;
}
/* PartFive */
.partFive .secondaryContainer {
    color: #fff;
}
.button {
    border-radius: 50px;
    border: 2px solid #479ff8;
    background-color: #479ff8;
    color: #fff;
    text-align: center;
    font-size: 18px;
    padding:6px;
    width: 215px;
    transition: all 0.5s;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 1%;
}

  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button:hover span {
    padding-right: 25px;
  }

  .button:hover span:after {
    opacity: 1;
    right: 0;
  }

.button-2 {
    border-radius: 5px;
    border: 2px solid grey;
    background-color: #fff;
    color: grey;
    text-align: center;
    font-size: 18px;
    padding:6px;
    width: 150px;
    transition: all 0.5s;
    cursor: pointer;
    margin-left: 0%;
    margin-top: 1%;
}

  .button-2 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button-2 span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button-2:hover span {
    padding-right: 25px;
  }

  .button-2:hover span:after {
    opacity: 1;
    right: 0;
  }

  .blogCard {
    transition: 00.5s;
  }

  .blogCard:hover {
    margin-top: -20px;
    transition: 00.5s;
  }


  @media (min-width: 1000px) {
    .container{
        max-width: 1100px;
    }
}
